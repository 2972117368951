<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-zm6">
        <div v-for="(item,index) in rowsData" class="data" :class="'cZ' + (index+1)" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul>
            <li :balls="'sb_' + ele.color" v-for="ele in item.arr" :key="ele.ResultID" :class="ele.selected ? 'selected' : ''" @click="setCurrentSelected(ele)">
              <ol style="width: 40px;min-width:40px" class="td_name">
                {{
                  ele.label
                }}
              </ol>
              <ol style="width: 43px;min-width:43px" class="td_rate">
                {{
                 ele.Odds
                }}
              </ol>
              <ol class="td_cash">
                <input type="text" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li style="width:40px" v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengma16Data } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  components: { topTimer, yushe, yanzheng },
  mixins: [mixins],
  props: [""],
  data() {
    return {
      groupnames:'zm16',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "正码一", arr: [] },
        { title: "正码二", arr: [] },
        { title: "正码三", arr: [] },
        { title: "正码四", arr: [] },
        { title: "正码五", arr: [] },
        { title: "正码六", arr: [] },
      ],

      labelArr: [
        "单码",
        "双码",
        "大码",
        "小码",
        "合单",
        "合双",
        "合大",
        "合小",
        "尾大",
        "尾小", //   在合双 后面
        "红波",
        "蓝波",
        "绿波",
      ],
    };
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 正码一
        let arr1 = this.ddFilterData1(
          resAArr,
          [634, 635, 636, 637, 638, 639, 798, 799, 810, 811, 640, 642, 641], //639后面少四个
          this.labelArr
        );
        arr1[arr1.length - 3].color = "red";
        arr1[arr1.length - 2].color = "blue";
        arr1[arr1.length - 1].color = "green";
        this.rowsData[0].arr = arr1;
        //  正码二
        let arr2 = this.ddFilterData1(
          resAArr,
          [643, 644, 645, 646, 647, 648, 800, 801, 812, 813, 649, 651, 650], //648后面少四个
          this.labelArr
        );
        arr2[arr2.length - 3].color = "red";
        arr2[arr2.length - 2].color = "blue";
        arr2[arr2.length - 1].color = "green";
        this.rowsData[1].arr = arr2;
        // 正码三
        let arr3 = this.ddFilterData1(
          resAArr,
          [652, 653, 654, 655, 656, 657, 802, 803, 814, 815, 658, 660, 659], //657后面少四个
          this.labelArr
        );
        arr3[arr3.length - 3].color = "red";
        arr3[arr3.length - 2].color = "blue";
        arr3[arr3.length - 1].color = "green";
        this.rowsData[2].arr = arr3;
        // 正码四
        let arr4 = this.ddFilterData1(
          resAArr,
          [661, 662, 663, 664, 665, 666, 804, 805, 816, 817, 667, 669, 668], //666后面少四个
          this.labelArr
        );
        arr4[arr4.length - 3].color = "red";
        arr4[arr4.length - 2].color = "blue";
        arr4[arr4.length - 1].color = "green";
        this.rowsData[3].arr = arr4;
        // 正码五
        let arr5 = this.ddFilterData1(
          resAArr,
          [670, 671, 672, 673, 674, 675, 806, 807, 818, 819, 676, 678, 677], //675后面少四个
          this.labelArr
        );
        arr5[arr5.length - 3].color = "red";
        arr5[arr5.length - 2].color = "blue";
        arr5[arr5.length - 1].color = "green";
        this.rowsData[4].arr = arr5;
        // 正码六
        let arr6 = this.ddFilterData1(
          resAArr,
          [679, 680, 681, 682, 683, 684, 808, 809, 820, 821, 685, 687, 686], //684后面少四个
          this.labelArr
        );
        arr6[arr6.length - 3].color = "red";
        arr6[arr6.length - 2].color = "blue";
        arr6[arr6.length - 1].color = "green";
        this.rowsData[5].arr = arr6;

        this.$forceUpdate();
      },
    },
  },
  created() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    // input聚焦 判断显示快速下注
  },
};
</script>
<style scoped>
.listmain > .data > ul > li .td_cash input {
  width: 32px !important;
}

.listmain.game-lhc-zm6 .data {
  margin-right: 1px;
  width: calc((100% - 10px) / 6);
}
</style>
